import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'select-area',
    loadChildren: () => import('./pages/select-area/select-area.module').then( m => m.SelectAreaPageModule)
  },
  {
    path: 'weight-adjuster',
    loadChildren: () => import('./pages/weight-adjuster/weight-adjuster.module').then( m => m.WeightAdjusterPageModule)
  },
  {
    path: 'liberator',
    loadChildren: () => import('./pages/liberator/liberator.module').then( m => m.LiberatorPageModule)
  },
  {
    path: 'print-invoice',
    loadChildren: () => import('./pages/print-invoice/print-invoice.module').then( m => m.PrintInvoicePageModule)
  },
  {
    path: 'logistics',
    loadChildren: () => import('./pages/logistics/logistics.module').then( m => m.LogisticsPageModule)
  },
  {
    path: 'form-logistic',
    loadChildren: () => import('./pages/form-logistic/form-logistic.module').then( m => m.FormLogisticPageModule)
  },
  {
    path: 'dispatch',
    loadChildren: () => import('./pages/dispatch/dispatch.module').then( m => m.DispatchPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    })
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  exports: [RouterModule]
})
export class AppRoutingModule {}
