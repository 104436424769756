import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-select-printer',
  templateUrl: './select-printer.component.html',
  styleUrls: ['./select-printer.component.scss'],
})
export class SelectPrinterComponent implements OnInit {

  //JSP PRINT MANAGER
  @Input() printers ;
  selectedPrinter: any;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    console.log(this.printers);
  }


  cancel() {
    const data= {
      confirm : false,
      selectedPrinter: this.selectedPrinter
    }
    return this.modalCtrl.dismiss(JSON.stringify(data), 'cancel');
  }

  confirm() {
    const data= {
      confirm : true,
      selectedPrinter: this.selectedPrinter
    }
    return this.modalCtrl.dismiss(JSON.stringify(data), 'confirm');
  }

}
